<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Configure facilmente suas lojas e caixas, definindo atributos como endereço, telefone e dados fiscais. Clique sobre uma loja para visualizar seus respectivos caixas.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

		<div class="col-12">
			<div class="card mb-2">
            <div class="card-body">
               <div class="row">
                  <div class="col">
							<label><i class="far fa-store color-theme font-12 me-1"></i> Filtrar</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus placeholder="Busque por nome, endereço, cnpj ou vencimento do certificado" />
                  </div>
                  <div class="w-max-content align-self-end" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar lojas')">
							<button type="button" class="btn btn-primary w-100 px-sm-5" @click="abrirModal(null)" v-if="dadosUsuario.maxLojas > pesquisa.resultado.length">
								<i class="far fa-plus font-13 me-1"></i> Adicionar
							</button>
							<button type="button" class="btn btn-outline-primary w-100 px-sm-5" @click="irParaPacotes" v-else><i class="far fa-star font-13 me-1"></i> Fazer upgrade</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="row lojas">
				<loja v-for="(loja, index) in pesquisa.resultado" :key="index" :loja="loja" :index="index" @editar="abrirModal($event)" />
			</div>
      </div>

		<!-- modalLoja -->
      <div class="modal fade" id="modalLoja" tabindex="-1" aria-labelledby="modalLojaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalLojaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} loja {{ modal.id == null ? '' : ('(nº '+ modal.numeroLoja +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-loja-tab" data-bs-toggle="pill" data-bs-target="#pills-loja" type="button" role="tab" aria-controls="pills-loja" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation" v-if="dadosUsuario.usuarioModulos.includes('Fiscal')">
										<button class="nav-link m-0" id="pills-fiscal-tab" data-bs-toggle="pill" data-bs-target="#pills-fiscal" type="button" role="tab" aria-controls="pills-fiscal" aria-selected="false">
											<i class="far fa-percentage font-13"></i><span class="ms-1 d-none d-sm-inline"> Fiscal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-pdvs-tab" data-bs-toggle="pill" data-bs-target="#pills-pdvs" type="button" role="tab" aria-controls="pills-pdvs" aria-selected="false">
											<i class="far fa-desktop font-12"></i><span class="ms-1 d-none d-sm-inline"> Caixas</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation" v-if="modal.id != null">
										<button class="nav-link m-0" id="pills-estoque-tab" data-bs-toggle="pill" data-bs-target="#pills-estoque" type="button" role="tab" aria-controls="pills-estoque" aria-selected="false">
											<i class="far fa-box font-12"></i><span class="ms-1 d-none d-sm-inline"> Estoque</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-loja" role="tabpanel" aria-labelledby="pills-loja-tab">
								<div class="accordion" id="accordion1">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion1-1" aria-expanded="true" aria-controls="accordion1-1">
												<i class="fal fa-info-circle"></i> Informações básicas
											</button>
										</h2>
										<div id="accordion1-1" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion1">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3 mb-sm-0">
														<label><i class="far fa-store color-theme font-12 me-1"></i> Nome *</label>
														<input type="text" class="form-control" v-model="modal.nomeFantasia" maxlength="200" />
													</div>
													<div class="col-sm-6">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número *</label>
														<input type="text" class="form-control" v-model="modal.numeroLoja" v-mask="'##########'" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion1-2" aria-expanded="false" aria-controls="accordion1-2">
												<i class="fal fa-map-marker-alt me-3"></i>Endereço
											</button>
										</h2>
										<div id="accordion1-2" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion1">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
															<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
																<i class="fas fa-search"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-7 mb-3">
														<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
														<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
													</div>
													<div class="col-sm-7 mb-3">
														<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
														<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
														<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
														<v-select name="Desktop" :options="estados" v-model="modal.endereco.estado" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.endereco.estado">
															<option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-sm-5 mb-3 mb-sm-0">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
														<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
													</div>
													<div class="col-sm-7">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
														<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion1-3" aria-expanded="false" aria-controls="accordion1-3">
												<i class="fal fa-phone-alt"></i> Telefone
											</button>
										</h2>
										<div id="accordion1-3" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion1">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-3 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
														<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
														<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
															<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-12">
														<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
														<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-fiscal" role="tabpanel" aria-labelledby="pills-fiscal-tab" v-if="dadosUsuario.usuarioModulos.includes('Fiscal')">
								<div class="card mb-2">
									<div class="card-body p-12">
										<div class="form-check mb-0">
											<input type="checkbox" class="form-check-input" id="pessoaFisica" v-model="modal.isFiscal">
											<label class="form-check-label" for="pessoaFisica"><i class="far fa-file font-11 color-theme mx-1"></i> Gerar notas fiscais</label>
										</div>
									</div>
								</div>

								<div class="accordion" id="accordion2">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion2-1" aria-expanded="true" aria-controls="accordion2-1">
												<i class="fal fa-info-circle"></i> Inscrição e situação cadastral
											</button>
										</h2>
										<div id="accordion2-1" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion2">
											<div class="accordion-body">
												<div class="row">
													<div class="col-12 mb-3">
														<label><i class="far fa-building color-theme font-12 me-1"></i> Razão social <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.razaoSocial" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-12 me-1"></i> CNPJ <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.cnpj" v-mask="['###.###.###-##', '##.###.###/####-##']" />
													</div>
													<div class="col-sm-6 mb-3">
														<label class="w-100">
															<i class="far fa-calculator color-theme font-12 me-1"></i> Insc. estadual <span v-if="modal.isFiscal">*</span>
															<small class="float-end text-secondary" v-if="modal.isFiscal" style="transform: translateY(1.5px);">Vazio caso isento</small>
														</label>
														<input type="text" class="form-control" v-model="modal.inscricaoEstadual" v-mask="'#########################'">
													</div>
													<div class="col-sm-6">
														<label><i class="far fa-hashtag color-theme font-12 me-1"></i> NSU <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.nsu" v-mask="'#########################'" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion2-2" aria-expanded="false" aria-controls="accordion2-2">
												<i class="fal fa-file-certificate"></i> Certificado
											</button>
										</h2>
										<div id="accordion2-2" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion2">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-calculator color-theme font-11 me-1"></i> Série NF-e <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.serie" v-mask="'##########'" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-file color-theme font-11 me-1"></i> Número NF-e <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.numeroNota" v-mask="'##########'" />
													</div>
													<div class="col-sm-8 mb-3 mb-sm-0" v-show="modal.dataVencimentoCertificado == null">
														<label><i class="far fa-file color-theme font-11 me-1"></i> Arquivo <span v-if="modal.isFiscal">*</span></label>
														<input type="file" class="form-control" ref="certificado" accept=".pfx" />
													</div>
													<div class="col-12" v-show="modal.dataVencimentoCertificado != null">
														<label><i class="far fa-file color-theme font-11 me-1"></i> Vencimento <span v-if="modal.isFiscal">*</span></label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modal.dataVencimentoCertificado" disabled />
															<a href="javascript:;" class="input-group-text font-13" @click="modal.dataVencimentoCertificado = null;">
																<i class="far fa-trash text-red"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-4" v-if="modal.dataVencimentoCertificado == null">
														<label><i class="far fa-lock color-theme font-12 me-1"></i> Senha</label>
														<input type="text" class="form-control key" v-model="modal.senhaCertificado" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion2-3" aria-expanded="false" aria-controls="accordion2-3">
												<i class="fal fa-code-branch ms-1"></i> Integração domínio
											</button>
										</h2>
										<div id="accordion2-3" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordion2">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3 mb-sm-0">
														<label><i class="far fa-barcode color-theme font-12 me-1"></i> Chave Domínio <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.integrationKeyDominio" maxlength="250" />
													</div>
													<div class="col-sm-6">
														<label><i class="far fa-barcode color-theme font-12 me-1"></i> Chave loja <span v-if="modal.isFiscal">*</span></label>
														<input type="text" class="form-control" v-model="modal.integrationKeyLoja" maxlength="250" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-pdvs" role="tabpanel" aria-labelledby="pills-pdvs-tab">
								<div class="card mb-2">
									<div class="card-body">
										<h6 class="mb-0 font-14 weight-400">
											<span>{{ modal.pdvs.length }} caixa(s) encontrado(s)</span>
											<a href="javascript:;" class="float-end font-16" data-bs-toggle="modal" data-bs-target="#modalCaixa"><i class="fas fa-plus color-theme"></i></a>
										</h6>
									</div>
								</div>
								<div class="row">
									<pdv v-for="(pdv, index) in modal.pdvs" :key="index" :pdv="pdv" :index="index" :showEdit="modal.id != null" :class="modal.isFiscal && 
										(pdv.serie == null || String(pdv.serie).trim().length == 0 || pdv.numeroNota == null || String(pdv.numeroNota).trim().length == 0) ? 'text-red' : ''"
										@remover="removerPDV($event)" @editar="editarPDV($event)" />
								</div>
							</div>

							<div class="tab-pane fade" id="pills-estoque" role="tabpanel" aria-labelledby="pills-estoque-tab" v-if="modal.id != null">
								<div class="card mb-1">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-12 mb-3">
												<label><i class="far fa-box color-theme font-11 me-1"></i> Entrada estoque *</label>
												<v-select name="Desktop" :options="['Manifestação nota', 'Recebimento']" v-model="modal.entradaEstoque" :clearable="false" :searchable="false" placeholder="Selecione uma opção">
													<template #option="option">Via {{ option.label }}</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.entradaEstoque">
													<option v-for="(option, index) in ['Manifestação nota', 'Recebimento']" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-12 mb-2">
												<label><i class="far fa-box color-theme font-11 me-1"></i> Estoque p/ vendas*</label>
												<v-select name="Desktop" :options="modal.estoque.filter(e => e.habilitado)" v-model="modal.idEstoqueVenda" :reduce="e => e.id" :clearable="false" label="nome" placeholder="Selecione uma opção">
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Selecione um estoque abaixo</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control select-sm" v-model="modal.idEstoqueVenda">
													<option v-for="(option, index) in modal.estoque.filter(e => e.habilitado)" :key="index" :value="option.id">{{ option.nome }}</option>
												</select>
											</div>
											<div class="col-12">
												<div class="alert alert-secondary alert-dismissible fade show mb-0 shadow-none pe-3 py-12 font-13" role="alert">
													<strong>Estoque p/ vendas</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
													<span>É desse estoque que as vendas realizadas no caixa serão abatidas.</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="card mb-1">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-12 px-0 mb-3">
												<div class="alert alert-secondary alert-dismissible fade show mb-0 shadow-none pe-3 py-12 font-13" role="alert">
													<strong>Estoques</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
													<span>Eles ficam disponíveis para a loja na emissão, manifestação e recebimento de notas pelo sistema.</span>
												</div>
											</div>
											<div class="col-12 px-0">
												<label><i class="far fa-boxes color-theme font-11 me-1"></i> Estoques</label>
											</div>

											<estoque v-for="(estoque, index) in modal.estoque" :key="index" :index="index" :estoque="estoque" />

											<div class="col-12 my-5 text-center" v-if="modal.estoque.length == 0">
												<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
												<p class="font-13 mt-4 mb-0 text-secondary">Nenhum estoque encontrado</p>
												<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarLoja">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalCaixa -->
      <div class="modal fade" id="modalCaixa" tabindex="-1" aria-labelledby="modalCaixaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalCaixaLabel">{{ modal.pdv.id == null ? 'Adicionar' : 'Editar' }} caixa</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalLoja" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body pb-0">
								<div class="row">
									<div class="col-12 mb-3" v-if="dadosUsuario.maxPdvsOffline > 0">
										<div class="form-check mb-0">
											<input type="checkbox" class="form-check-input" id="caixaOffline" v-model="modal.pdv.isOffline">
											<label class="form-check-label" for="caixaOffline"><i class="far fa-wifi font-11 color-theme mx-1"></i> Caixa offline</label>
										</div>
									</div>
									<div class="col-12 mb-3">
										<label><i class="far fa-desktop color-theme font-11 me-1"></i> Nome</label>
										<input type="text" class="form-control" v-model="modal.pdv.nome" maxlength="200" />
									</div>
									<div class="col mb-3" v-if="modal.isFiscal && dadosUsuario.usuarioModulos.includes('Fiscal')">
										<label><i class="far fa-calculator color-theme font-11 me-1"></i> Série NFC-e <span v-if="modal.isFiscal">*</span></label>
										<input type="text" class="form-control" v-model="modal.pdv.serie" v-mask="'##########'" />
									</div>
									<div class="col mb-3" v-if="modal.isFiscal && dadosUsuario.usuarioModulos.includes('Fiscal')">
										<label><i class="far fa-file color-theme font-11 me-1"></i> Número NFC-e <span v-if="modal.isFiscal">*</span></label>
										<input type="text" class="form-control" v-model="modal.pdv.numeroNota" v-mask="'##########'" />
									</div>
								</div>
								<div class="row">
									<div class="col mb-3" v-if="modal.pdv.isOffline && dadosUsuario.maxPdvsOffline > 0">
										<label><i class="far fa-cloud color-theme font-11 me-1"></i> Versão carga</label>
										<input type="text" class="form-control" v-model="modal.pdv.versaoCarga" v-mask="'##########'" />
									</div>
									<div class="col mb-3">
										<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número caixa</label>
										<input type="text" class="form-control" v-model="modal.pdv.numeroPDV" v-mask="'##########'" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="savePDV">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalLoja">Voltar</button>
               </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import pdv from '@/components/configuracoes/lojas/PDV.vue'
import loja from '@/components/configuracoes/lojas/Loja.vue'
import estoque from '@/components/configuracoes/lojas/CheckEstoque.vue'

export default {
	name: 'Lojas',
	data: function () {
		return {
			pesquisa: {'nome': null, 'totalPDVs': 0, 'resultado': []},
			modal: {
				'id': null, 'cnpj': '', 'inscricaoEstadual': '', 'nomeFantasia': '', 'nsu': '', 'numeroLoja': '', 'razaoSocial': '', 'senhaCertificado': '', 'urlSchema': '', 'idEstoqueVenda': null,
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'pdvs': [], 'dataVencimentoCertificado': null, 'entradaEstoque': 'Recebimento',
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''}, 
				'pdv': {'id': null, 'idLoja': null, 'nome': '', 'numeroNota': '', 'numeroPDV': '', 'serie': '', 'versaoCarga': '', 'isOffline': false}, 'estoque': [],
				'integrationKeyLoja': null, 'integrationKeyDominio': null, 'isFiscal': false, 'serie': null, 'numeroNota': null
			}
		}
	},
	computed: {
      ... mapState({
         listaEstoques: state => state.listaEstoques,
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         estados: state => state.estados,
         tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarLojas()
      },
		'modal.estoque': {
			handler: function() {
				if (this.modal.estoque.filter(e => e.habilitado).flatMap(e => e.id).includes(this.modal.idEstoqueVenda) == false) {
					this.modal.idEstoqueVenda = null
				}
			},
			deep: true
		},
      'pesquisa.nome' : function (val) {
         var value = val.toLowerCase()

         $(".row.lojas > *").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
	components: {
		loja, pdv, estoque
	},
	methods: {
		abrirModal : function (loja) {
			if (this.dadosUsuario.usuarioModulos.includes('Fiscal')) {
				this.$refs.certificado.value = null;
			}

			if (loja == null) {
				this.modal = {
					'id': null, 'cnpj': '', 'inscricaoEstadual': '', 'nomeFantasia': '', 'nsu': '', 'numeroLoja': '', 'razaoSocial': '', 'senhaCertificado': '', 'urlSchema': '', 'idEstoqueVenda': null,
					'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'pdvs': [], 'dataVencimentoCertificado': null, 'entradaEstoque': 'Recebimento',
					'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''}, 
					'pdv': {'id': null, 'idLoja': null, 'nome': '', 'numeroNota': '', 'numeroPDV': '', 'serie': '', 'versaoCarga': '', 'isOffline': false}, 
					'estoque': JSON.parse(JSON.stringify(this.listaEstoques)), 'integrationKeyLoja': null, 'integrationKeyDominio': null, 'isFiscal': false, 'serie': null, 'numeroNota': null
				}
			} else {
				this.modal = {
					'id': loja.id, 
					'cnpj': loja.cnpj, 
					'inscricaoEstadual': loja.inscricaoEstadual, 
					'nomeFantasia': loja.nomeFantasia, 
					'nsu': loja.nsu, 
					'numeroLoja': loja.numeroLoja, 
					'razaoSocial': loja.razaoSocial, 
					'senhaCertificado': loja.senhaCertificado, 
					'dataVencimentoCertificado': loja.dataVencimentoCertificado == null ? null : String(loja.dataVencimentoCertificado.dayOfMonth).padStart(2, '0') +'/'+ String(loja.dataVencimentoCertificado.monthValue).padStart(2, '0') +'/'+ loja.dataVencimentoCertificado.year, 
					'urlSchema': loja.urlSchema, 
					'entradaEstoque': loja.entradaEstoque,
					'integrationKeyLoja': loja.integrationKeyLoja, 
					'integrationKeyDominio': loja.integrationKeyDominio, 
					'isFiscal': loja.isFiscal, 
					'serie': loja.serie, 
					'numeroNota': loja.numeroNota,
					'endereco': loja.endereco, 
					'telefone': loja.telefone,
					'pdvs': JSON.parse(JSON.stringify(loja.pdvs)),
					'estoque': JSON.parse(JSON.stringify(loja.estoques)),
					'idEstoqueVenda': loja.idEstoqueVenda,
					'pdv': {'id': null, 'idLoja': null, 'nome': '', 'numeroNota': '', 'numeroPDV': '', 'serie': '', 'versaoCarga': '', 'isOffline': false}
				}

				setTimeout(() => this.modal.idEstoqueVenda = loja.idEstoqueVenda, 100);
			}

			$('#modalLoja').modal('show')
		},
		irParaPacotes : function () {
			$('#modalLoja').modal('hide')
			router.push('/minhaConta/'+ this.tabs.length)
		},
		buscarLojas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.resultado = []
			this.pesquisa.totalPDVs = 0

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/searchLojas'

			}).then(response => {
				this.pesquisa.resultado = response.data
				response.data.forEach(loja => this.pesquisa.totalPDVs += loja.pdvs.length);

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		savePDV : function () {
			if (this.modal.isFiscal && (this.modal.pdv.serie == null || String(this.modal.pdv.serie).trim().length == 0 ||
				this.modal.pdv.numeroNota == null || String(this.modal.pdv.numeroNota).trim().length == 0)) {
				
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			if (this.modal.pdv.numeroPDV == null || String(this.modal.pdv.numeroPDV).trim().length == 0) {
				this.modal.pdv.numeroPDV = this.modal.pdvs.length + 1
			}

			if (this.modal.pdv.nome == null || String(this.modal.pdv.nome).trim().length == 0) {
				this.modal.pdv.nome = 'Caixa '+ this.modal.pdv.numeroPDV
			}

			this.modal.pdv.idLoja = this.modal.id

			if (this.modal.pdv.id == null) {
				this.modal.pdvs.push(JSON.parse(JSON.stringify(this.modal.pdv)))
				
			} else {
				let index = this.modal.pdvs.findIndex(pdv => pdv.id == this.modal.pdv.id)
				this.$set(this.modal.pdvs, index, JSON.parse(JSON.stringify(this.modal.pdv)))
			}

			$('#modalCaixa').modal('hide')
			$('#modalLoja').modal('show')
			Object.assign(this.$data.modal.pdv, this.$options.data.apply(this).modal.pdv)
		},
		editarPDV : function (pdv) {
			this.modal.pdv = {
				'id': pdv.id, 
				'idLoja': pdv.idLoja, 
				'isOffline': pdv.isOffline, 
				'nome': pdv.nome, 
				'numeroNota': pdv.numeroNota, 
				'numeroPDV': pdv.numeroPDV, 
				'serie': pdv.serie, 
				'versaoCarga': pdv.versaoCarga
			}

			$('#modalLoja').modal('hide')
			$('#modalCaixa').modal('show')
			$("#modalLoja .modal-body").animate({scrollTop: 0}, 600);
		},
		removerPDV : function (index) {
			this.modal.pdvs.splice(index, 1)
		},
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarLoja : function () {
			if (this.modal.nomeFantasia == null || String(this.modal.nomeFantasia).trim().length == 0 ||
				this.modal.numeroLoja == null || String(this.modal.numeroLoja).trim().length == 0 ||
				(this.modal.isFiscal && (this.modal.razaoSocial == null || String(this.modal.razaoSocial).trim().length == 0 ||
				this.modal.cnpj == null || String(this.modal.cnpj).trim().length == 0 ||
				this.modal.nsu == null || String(this.modal.nsu).trim().length == 0 ||
				this.modal.numeroNota == null || String(this.modal.numeroNota).trim().length == 0 ||
				this.modal.serie == null || String(this.modal.serie).trim().length == 0 ||
				this.modal.integrationKeyLoja == null || String(this.modal.integrationKeyLoja).trim().length == 0 ||
				this.modal.integrationKeyDominio == null || String(this.modal.integrationKeyDominio).trim().length == 0 ||
				(this.dadosUsuario.usuarioModulos.includes('Fiscal') && this.modal.dataVencimentoCertificado == null && this.$refs.certificado.files.length == 0))) ||
				(this.modal.id != null && (this.modal.idEstoqueVenda == null || String(this.modal.idEstoqueVenda).trim().length == 0))) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			if (this.modal.isFiscal && this.modal.pdvs.filter(pdv => pdv.serie == null || String(pdv.serie).trim().length == 0 || 
				pdv.numeroNota == null || String(pdv.numeroNota).trim().length == 0).length > 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Existem caixas com série e/ou nº nota NFC-e inválidos'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)
			let dataForm = new FormData();
			dataForm.append('info', JSON.stringify(this.modal));

			if (this.dadosUsuario.usuarioModulos.includes('Fiscal')) {
				for (let file of this.$refs.certificado.files) {
					dataForm.append('file', file, String(file.name).replace(/[^\w\s.]/gi, ''));
				}
			}

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/saveLoja',
				headers: {'Content-Type': 'multipart/form-data'},
				data: dataForm

			}).then(() => {
				$('#modalLoja').modal('hide')
				this.buscarLojas()

				this.$toast.fire({
					icon: 'success',
					title: 'Loja salva!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 409) {
						this.$toast.fire({
							icon: 'error',
							title: 'Certificado inválido!'
						});
					} else if (error.response.status == 403) {
						this.$toast.fire({
							icon: 'error',
							title: 'CNPJ já cadastrado!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de lojas/caixas excedido!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		this.$store.dispatch('getEstoques')
      this.buscarLojas()
	}
}

</script>